import { ABILITIES, AUTH_NAMESPACE, USER } from "store/modules/auth";

import moment from "moment";

const mixin = {
  computed: {
    /**
    * Retorna o usuário logado salvo no $store
    */
    $user() {
      return {
        ...this.$store.getters[`${AUTH_NAMESPACE}/${USER}`],
        permissions: this.$store.getters[`${AUTH_NAMESPACE}/${ABILITIES}`]
      }
    },
    /**
    * Retorna a propriedade padrão usada em todas as tabelas
    */
    $footerProps() {
      return {
        'items-per-page-options': [5, 10, 15, 25, 50, -1],
      };
    },
    /**
    * Retorna os tipos de condição usados no SCAE
    */
    $tipoCondicaoOptions() {
      return [
        { descricao: 'Discente', value: 'discente' },
        { descricao: 'Docente', value: 'docente' },
      ]
    },
    /**
    * Retorna a data/hora de hoje formatada
    */
    $hoje() {
      return new Date().toLocaleDateString()
    },
    $buildDev() {
      return this.$configurations.env.nodeEnv == "development"
    },
    $recaptchaDev() {
      return this.$configurations.env.recaptcha;
    }
  },
  methods: {
    /**
    * Redireciona para a página home
    * @param app Opcional - path home a ser redirecionado
    */
    $toHome(app = "/App") {
      this.$router.push({ path: app });
    },
    /**
    * Retorna uma página no histórico
    */
    $back() {
      this.$router.go(-1);
    },
    /**
    * Reseta o form padrão ref="form" e o captcha caso exista
    * @param form Opcional - Referência da tag form em tela
    */
    $resetForm(form) {
      form ? form.reset() : this.$refs.form.reset();
      this.$refs.recaptcha && this.$refs.recaptcha.onCaptchaExpired();
    },
    /**
    * Valida o formulário mostrando a mensagem caso inválido
    * @param msg Opcional - Mensagem de campos obrigatórios
    */
    $validarFormulario(msg = "Preencha todos os campos obrigatórios!") {
      return this.$refs.form.validate()
        ? true
        : this.notifyWarning(msg)
    },
    /**
    * Converte um base64 em um new File()
    * @param base64 String de base64
    * @param filename Nome do arquivo gerado
    * @param mimeType Formato do arquivo
    */
    $convertBase64ToFile(base64, filename, mimeType) {
      return (fetch(`data:${mimeType};base64,${base64}`)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }))
      );
    },
    /**
    * Formata o CPF no formato padrão '###.###.###-##'
    * @param value Valor do CPF a ser formatado
    */
    $formatCpf(cpf) {
      return (cpf || '').replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
    /**
    * Retira a máscara do CPF '###.###.###-##' e retorna '###########'
    * @param value Valor do CPF mascarado
    */
    $unMask(value) {
      if (!value) return "";
      return value.replace(/\D/g, "");
    },
    /**
     * Remove a máscara de CNPJ e retorna apenas os dígitos.
     * @param value Valor do CNPJ mascarado
     */
    $unMaskCnpj(value) {
      if (!value) return "";
      
      // Remove pontos, traços e barras
      return value.replace(/\D/g, "");
    },
    /**
    * Ignora caso o usuário não tenha digitado um número no input
    * usar em conjunto do @keypress="$event"
    * @param evt Evento recebido pelo input
    */
    $verificarCaracterPortaria(evt) {
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.'].includes(evt.key)) {
        evt.preventDefault();
      }
    },
    /**
    * Pemitir apenas dígitos, '-' e '.' para os números de Portaria
    * usar em conjunto do @keypress="$event"
    * @param evt Evento recebido pelo input
    */
    $isNumber(evt) {
      if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(evt.key)) {
        evt.preventDefault();
      }
    },
    /**
    * Rola a página até o topo
    */
    $scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    /**
    * Esconde o CPF em tela adicionando # no formato '###.###.000-##'
    * @param value Valor do CPF a ser mascarado
    */
    $esconderCpf(value) {
      return this.$formatCpf(value)
        .split("")
        .map((v, i) => {
          return [8, 9, 10].includes(i) || v.includes(".") || v.includes("-")
            ? v
            : "*";
        })
        .toString()
        .split(",")
        .join("");
    },
    /**
    * Retorna a data/hora formatada
    */
    $formatarDataHora(value) {
      if (!value) {
        return '';
      }

      try {
        return moment(value).format('DD-MM-YYYY HH:mm:ss');
      } catch {
        console.warn('Data/Hora inválida: ', value);
        return '';
      }
    },

    /**
    * Retorna a data formatada
    */
    $formatarData(value) {
      if (!value) {
        return '';
      }

      try {
        return moment(value).format('DD/MM/YYYY');
      } catch {
        console.warn('Data inválida: ', value);
        return '';
      }
    },
    
    /**
    * Formata o date em formato americano para enviar para a api
    * @param data String da data a ser enviada para o back
    * @param hora Opcional - String da Hora em formato 'hh:mm:ss'
    */
    $formatarDateParaEnviar(data, hora = "00:00:00") {
      if (data && data.includes("/")) {
        const [dia, mes, ano] = data.split("/");
        return `${ano}-${mes}-${dia}T${hora}`;
      } else {
        return `${data}T${hora}`;
      }
    }, 
    /**
    * Esconde o CPF em tela adicionando * no formato '***.***.000-**'
    * @param value Valor do CPF a ser mascarado
    */
    $formatCpfCnpj(value) {
      const cleanedValue = this.$unMask(value).trim(); // Remove a máscara, se houver
      if (cleanedValue.length === 11) { // CPF
        return `***.${cleanedValue.substring(3, 6)}.***-${cleanedValue.substring(9)}`;
      } else if (cleanedValue.length === 14) { // CNPJ
        return this.$formatCnpj(cleanedValue);
      } else {
        return value;
      }
    },
    $unmaskCpfCnpjForBackend(value) {
      return value.replace(/\D/g, '');
    },
    $formatSEI(sei) {
      if (!sei) {
        return '';
      }

      // Remove caracteres não numéricos
      const numeroLimpo = sei.replace(/[^\d]/g, '');

      // Aplica a máscara correta
      const seiFormatado = `${numeroLimpo.substr(0, 13)}.${numeroLimpo.substr(13, 3)}/${numeroLimpo.substr(16, 4)}-${numeroLimpo.substr(20, 2)}${numeroLimpo.substr(22, 2)}`;

      return seiFormatado;
    },
    $formatCnpj(cnpj) {
      if (!cnpj) {
        return '';
      }
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    },
    /**
    * Aplicar o formato (com pontos) a CPF/CNPJ, conforme o tamanho da informação.
    * @param value Valor do CPF/CNPJ a ser mascarado
    */
    $formatCpfCnpjSemEsconder(value) {
      const cleanedValue = this.$unMask(value).trim();
      if (cleanedValue.length === 11) { // CPF
        return `${cleanedValue.substring(0, 3)}.${cleanedValue.substring(3, 6)}.${cleanedValue.substring(6, 9)}-${cleanedValue.substring(9)}`;
      } else if (cleanedValue.length === 14) { // CNPJ
        return this.$formatCnpj(cleanedValue);
      } else {
        return cleanedValue;
      }
    },
    /**
    * Monta os params corretamente enviando null no lugar de 'Todos'.
    * @param filters Objeto contendo os filtros informados em tela Ex: this.form.filters
    */
    $montarParamsFilters(filters) {
      return {
        params: {
          ...filters,
          situacao: filters.situacao == "Todas" ? null : filters.situacao,
          ano: filters.ano == "Todos" ? null : filters.ano,
          semestre: filters.semestre == "Todos" ? null : filters.semestre
        }
      }
    },
    /**
    * Cria um elemento CSV na tela e realiza o download no navegador do usuário.
    * @param bytes Array de bytes retornado pelo back-end
    * @param nomeDoRelatorio Nome do relatório (csv) a ser baixado
    */
    $baixarRelatorioCSV(bytes, nomeDoRelatorio = "relatorio") {
      const getEncodeData = () => encodeURIComponent(`\uFEFF${bytes}`);
      const a = window.document.createElement("a");
      a.setAttribute("href", `data:text/csv; charset=utf-8,${getEncodeData()}`);
      a.setAttribute("download", `${nomeDoRelatorio}-${Date.now()}.csv`);
      a.click();
      this.notifySuccess("Relatório baixado com sucesso!");
    },
    $base64ToArrayBuffer(data) {
      var bString = window.atob(data);
      var bLength = bString.length;
      var bytes = new Uint8Array(bLength);
      for (var i = 0; i < bLength; i++) {
          var ascii = bString.charCodeAt(i);
          bytes[i] = ascii;
      }
      return bytes;
    },

    $getFileFromBase64(base64, fileName, fileType, extension) {
        const bufferArray = this.$base64ToArrayBuffer(base64);
        const blob = new Blob([bufferArray], { type: fileType });
        return new File([blob], `${fileName}.${extension}`)
    },
    $baixarArquivo(file, fileType) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        const blob = new Blob([file], { type: fileType });
        const objectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.notifySuccess("Relatório baixado com sucesso!");
      },

      $baixarRelatorioPDFs(bytes, nomeDoRelatorio = "relatorio") {
        const getEncodeData = () => encodeURIComponent(`\uFEFF${bytes}`);
        const a = window.document.createElement("a");
        a.setAttribute("href", `data:application/pdf; charset=utf-8,${getEncodeData()}`);
        a.setAttribute("download", `${nomeDoRelatorio}-${Date.now()}.pdf`);
        a.click();
        this.notifySuccess("Relatório baixado com sucesso!");
      },
      $validateCpf(value) {
        // Remova caracteres não numéricos
        const cleanedValue = value.replace(/\D/g, '');
    
        // Verifique se o CPF tem 11 dígitos
        if (cleanedValue.length !== 11) {
          return false;
        }
    
        // Verifique se todos os dígitos são iguais, o que indica um CPF inválido
        if (/^(\d)\1+$/.test(cleanedValue)) {
          return false;
        }
    
        // Validação do primeiro dígito verificador
        let sum = 0;
        for (let i = 0; i < 9; i++) {
          sum += parseInt(cleanedValue.charAt(i)) * (10 - i);
        }
        let remainder = 11 - (sum % 11);
        remainder = remainder === 10 || remainder === 11 ? 0 : remainder;
    
        if (remainder !== parseInt(cleanedValue.charAt(9))) {
          return false;
        }
    
        // Validação do segundo dígito verificador
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += parseInt(cleanedValue.charAt(i)) * (11 - i);
        }
        remainder = 11 - (sum % 11);
        remainder = remainder === 10 || remainder === 11 ? 0 : remainder;
    
        if (remainder !== parseInt(cleanedValue.charAt(10))) {
          return false;
        }
    
        return true;
      },
    
      $validateCnpj(value) {
        // Remova caracteres não numéricos
            const cleanedValue = value.replace(/\D/g, '');
        
            // Verifique se o CNPJ tem 14 dígitos
            if (cleanedValue.length !== 14) {
              return false;
            }
        
            // Validação do primeiro dígito verificador
            let sum = 0;
            let factor = 5;
            for (let i = 0; i < 12; i++) {
              sum += parseInt(cleanedValue.charAt(i)) * factor;
              factor = factor === 2 ? 9 : factor - 1;
            }
            let remainder = sum % 11;
            remainder = remainder < 2 ? 0 : 11 - remainder;
        
            if (remainder !== parseInt(cleanedValue.charAt(12))) {
              return false;
            }
        
            // Validação do segundo dígito verificador
            sum = 0;
            factor = 6;
            for (let i = 0; i < 13; i++) {
              sum += parseInt(cleanedValue.charAt(i)) * factor;
              factor = factor === 2 ? 9 : factor - 1;
            }
            remainder = sum % 11;
            remainder = remainder < 2 ? 0 : 11 - remainder;
        
            if (remainder !== parseInt(cleanedValue.charAt(13))) {
              return false;
            }
        
            return true;
          },
          isCnpjValid(cnpj) {
            const cleanCnpj = cnpj.replace(/\D/g, ''); // Remove caracteres não numéricos

        if (cleanCnpj.length !== 14) {
          return false; // Deve ter 14 dígitos
        }

        if (/^(\d)\1+$/.test(cleanCnpj)) {
          return false; // Não pode ter todos os dígitos iguais
        }

        const weights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        let sum = 0;

        for (let i = 0; i < 12; i++) {
          sum += parseInt(cleanCnpj[i]) * weights[i];
        }

        const mod = sum % 11;
        const firstDigit = (mod < 2) ? 0 : 11 - mod;

        if (parseInt(cleanCnpj[12]) !== firstDigit) {
          return false; // Primeiro dígito verificador incorreto
        }

        sum = 0;

        for (let i = 0; i < 13; i++) {
          sum += parseInt(cleanCnpj[i]) * weights[i + 1];
        }

        const secondMod = sum % 11;
        const secondDigit = (secondMod < 2) ? 0 : 11 - secondMod;

        return parseInt(cleanCnpj[13]) === secondDigit;
      },
  
      validateCnpj(v) {
        const isValidCnpj = this.isCnpjValid(v);
        return isValidCnpj || 'CNPJ inválido';
      },
      isCepValid(cep) {
        // Adicione sua lógica de validação de CEP aqui
        return /^[0-9]{8}$/.test(cep);
      },
  
      validateCep(v) {
        const isValidCep = this.isCepValid(v);
        return isValidCep || 'CEP inválido';
      },
  },
};
export default mixin;