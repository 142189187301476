// Vue Core
import Vue from 'vue'
import VuePageTransition from 'vue-page-transition'
import VueGtag from "vue-gtag";
import VueSanitize from "vue-sanitize";
import App from './App.vue'

import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  UPDATE_BACKGROUND_IMAGE_VISIBILITY
} from "store/modules/pre-login-layout"
import { AUTH_NAMESPACE, REFRESH_TOKEN,} from "store/modules/auth"

import '@mdi/font/css/materialdesignicons.css'
import 'animate.css/animate.css'
import 'assets/styles/app.scss'
import './registerServiceWorker'

import axiosPlugin from 'plugins/axios'
import vuetify from 'plugins/vuetify'
import dayJsPlugin from 'plugins/dayjs'

import routerFactory from 'router'
import store from 'store'
import configurations from "commons/configurations"
import moment from "moment";
import { VueMaskDirective } from "v-mask";
import VueSweetAlert from "vue-sweetalert";
import { abilitiesPlugin} from '@casl/vue';
import ability from 'services/abilities/ability';

import UtilMixin from "~/mixins/util.mixin";
import MenuMixin from "~/mixins/menu.mixin";
import NotificationMixin from "~/mixins/notification";
import ValidationsMixin from "~/mixins/validations";
import ButtonsComponent from "~/components/ButtonsComponent.vue";
import BreadcrumbsComponent from "~/components/BreadcrumbsComponent.vue";
import TooltipComponent from "~/components/TooltipComponent.vue";
import AtivoSelectComponent from "~/components/AtivoSelectComponent.vue";
import AtivoLabelComponent from "~/components/AtivoLabelComponent.vue";
import QualificacaoComponent from '~/components/QualificacaoSelectComponent.vue';
import MudarSenhaComponent from '~/components/MudarSenhaComponent.vue';
import DatePickerComponent from "~/components/DatePickerComponent.vue";
import SwitchButtonComponent from "~/components/SwitchButtonComponent.vue";
import AtivoInativoComponent from "~/components/AtivoInativoComponent.vue";

import AuthService from "~/services/auth.service";
import AdministracaoService from "~/services/administracao.service";
import AdministracaoRelatoriosService from "~/services/administracao-relatorios.service";
import CredenciamentoService from "~/services/credenciamento.service";
import GerenciarUsuario from "~/services/gerenciar-usuario.service";
import GerenciarPerfil from "~/services/gerenciar-perfil.service";
import GerenciarGrupo from "~/services/gerenciar-grupo.service";
import GerenciarMantenedor from "~/services/gerenciar-mantenedores.service"
import GerenciarFinalidade from "~/services/gerenciar-finalidade.service"
import GerenciarOfertaEnsino from "~/services/gerenciar-oferta-ensino.service"
import GerenciarFAQ from "~/services/gerenciar-faq.service";
import GerenciarUnidadeDeEnsino from "~/services/gerenciar-unidade-de-ensino.service";
import RelatoriosService from "~/services/relatorios.service";

Vue.config.productionTip = false
Vue.prototype.$moment = moment;

// Generic
Vue.mixin(UtilMixin);
Vue.mixin(MenuMixin);
Vue.mixin(NotificationMixin);
Vue.mixin(ValidationsMixin);

// Services
Vue.mixin(AuthService);
Vue.mixin(AdministracaoService);
Vue.mixin(CredenciamentoService);
Vue.mixin(GerenciarUsuario);
Vue.mixin(GerenciarPerfil);
Vue.mixin(GerenciarGrupo);
Vue.mixin(GerenciarMantenedor);
Vue.mixin(GerenciarFinalidade);
Vue.mixin(GerenciarOfertaEnsino);
Vue.mixin(GerenciarFAQ);
Vue.mixin(GerenciarUnidadeDeEnsino);
Vue.mixin(AdministracaoRelatoriosService);
Vue.mixin(RelatoriosService);

// Components
Vue.component('scae-breadcrumbs', BreadcrumbsComponent);
Vue.component('scae-buttons', ButtonsComponent);
Vue.component('scae-tooltip', TooltipComponent);
Vue.component('scae-select-ativo', AtivoSelectComponent);
Vue.component('scae-label-ativo', AtivoLabelComponent);
Vue.component('scae-select-qualificacao', QualificacaoComponent);
Vue.component('scae-mudar-senha', MudarSenhaComponent);
Vue.component("scae-date-picker", DatePickerComponent);
Vue.component("scae-switch-button", SwitchButtonComponent);
Vue.component("scae-ativo-inativo", AtivoInativoComponent);

// Plugins
Vue.use(VuePageTransition)
Vue.use(VueSweetAlert);

// Directives
Vue.directive("mask", VueMaskDirective);

Vue.use(VueSanitize, {
  allowedAttributes: {
    'a': [ 'href', 'class', 'target' ]
  }
});

Vue.use(dayJsPlugin, {
  locale: 'pt-br'
})

Vue.use(axiosPlugin, {
  baseURL: configurations.api.baseUri
})

Vue.use(abilitiesPlugin, ability)

const router = routerFactory(store)

if(configurations.google.id) {
  Vue.use(VueGtag, {
    config: { id: configurations.google.id },
    appName: configurations.app.name,
    pageTrackerScreenviewEnabled: true,
    pageTrackerUseFullPath: true
  }, router)
}

new Vue({
  data: () => ({
    loading: {
      visible: true,
      content: undefined,
      progress: undefined,
      opacity: undefined
    },
    notification: {
      visible: undefined,
      timeout: undefined,
      color: undefined,
      light: undefined,
      centered: undefined,
      absolute: undefined,
      rounded: undefined,
      top: true,
      right: true,
      bottom: undefined,
      left: undefined,
      content: undefined,
      closable: true
    }
  }),
  router: router,
  store: store,
  vuetify,
  // mounted() {
  //   this.$store.dispatch(`${AUTH_NAMESPACE}/${REFRESH_TOKEN}`)
  //     .catch(() => {
  //       this.$store.dispatch(`${AUTH_NAMESPACE}/${LOGOUT}`)
  //     })
  // },
  mounted() {
    this.hidePageLoading();
    this.$store.dispatch(`${AUTH_NAMESPACE}/${REFRESH_TOKEN}`);
  },
  methods: {
    showPageLoading() {
      this.loading.visible = true;
    },
    hidePageLoading() {
      this.loading.visible = false;
    },
    showDefaultBackgroundImage() {
      this.$store.dispatch(
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${UPDATE_BACKGROUND_IMAGE_VISIBILITY}`,
        true
      );
    },
    hideDefaultBackgroundImage() {
      this.$store.dispatch(
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${UPDATE_BACKGROUND_IMAGE_VISIBILITY}`,
        false
      );
    },
  },
  render: h => h(App)
}).$mount('#app')
