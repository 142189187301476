const baseURL = "/Administracao/perfis";

const service = {
  methods: {
    $obterPerfis(params) {
        return this.$api().get(`${baseURL}`, params);
    },
    $visualizarPerfil(id, params) {
        return this.$api().get(`${baseURL}/${id}`, params);
    },

    $inativarAtivarPerfil(body) {
        return this.$api().put(`${baseURL}/alterarStatus`, body);
    },

    $cadastrarPerfil(params) {
        return this.$api().post(`${baseURL}`, params);
    },

    $atualizarPerfil(body) {
        return this.$api().put(`${baseURL}`, body);
    },
  },
};

export default service;