import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12","sm":_vm.cols}},[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"readonly":"","outlined":"","dense":"","disabled":_vm.disabled,"append-icon":_vm.removeIcon ? '' : 'mdi-calendar',"label":_vm.label,"dataEdicao":_vm.dataEdicao,"rules":_vm.required ? [function (v) { return _vm.$required(v); }, function (v) { return _vm.$validarDate(v); }] : []},model:{value:(_vm.computedDateFormattedCompleted),callback:function ($$v) {_vm.computedDateFormattedCompleted=$$v},expression:"computedDateFormattedCompleted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dataAtual),callback:function ($$v) {_vm.dataAtual=$$v},expression:"dataAtual"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }