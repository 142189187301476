import Vue from 'vue'
import VueRouter from 'vue-router'

import configurations from 'commons/configurations'
import {
  AUTH_NAMESPACE,
  USER
} from 'store/modules/auth.js'
import PreLoginLayout from 'views/PreLogin/BasePreLogin.vue'
import PostLoginLayout from 'views/PostLogin/BasePostLogin.vue'
import ExamplesLayout from 'views/Examples/BaseExamples.vue'

Vue.use(VueRouter)

const routerFactory = function (store) {
  const routes = [
    {
      path: '*',
      name: 'not-found',
      component: () =>
        import(/* webpackChunkName: "not-found" */ 'views/404.vue'),
    },
    {
      path: '/',
      component: PreLoginLayout,
      children: [
        {
          path: '/',
          name: 'landingpage',
          component: () =>
            import(
              /* webpackChunkName: "landingpage" */ 'views/PreLogin/BaseLandingPage.vue'
            ),
          meta: {
            title: configurations.app.name,
          },
        },
        {
          path: 'Login',
          name: 'login',
          component: () =>
            import(/* webpackChunkName: "login" */ 'views/PreLogin/Login.vue'),
          meta: {
            title: 'Entrar',
            showBackground: true,
          },
        },
        {
          path: 'EsqueciASenha',
          name: 'passwordrecovery',
          component: () =>
            import(
              /* webpackChunkName: "password-recovery" */ 'views/PreLogin/PasswordRecovery.vue'
            ),
          meta: {
            title: 'Esqueci a Senha',
            showBackground: true,
          },
        },
        {
          path: 'NovaSenha/:username?/:token?',
          name: 'newpassword',
          component: () =>
            import(
              /* webpackChunkName: "new-password" */ 'views/PreLogin/NewPassword.vue'
            ),
          meta: {
            title: 'Mudar Senha',
            showBackground: true,
          },
        },
      ],
    },
    {
      path: '/App',
      component: PostLoginLayout,
      meta: {
        requiresAuth: true,
        title: 'Início',
      },
      children: [
        {
          path: '/',
          name: 'welcome',
          component: () =>
            import(
              /* webpackChunkName: "welcome" */ 'views/PostLogin/Welcome.vue'
            ),
          meta: {
            title: 'Início',
            showBackground: true,
          },
        },
        {
          path: '/consentimento-uso-de-dados-pessoais',
          name: 'ConsentimentoDadosPessoaisView',
          component: () =>
            import(
              /* webpackChunkName: "termo" */ '~/views/PostLogin/termo-consentimento-lgpd/ConsentimentoDadosPessoaisView.vue'
            ),
          meta: {
            title: 'Consentimento de Uso de Dados Pessoais',
            breadcrumb: 'Consentimento de Uso de Dados Pessoais',
          },
        },
        {
          path: 'cadastro/gerenciar-usuario',
          name: 'Gerenciar Usuário',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-usuario/ManterUsuariosView.vue'
            ),
          meta: {
            title: 'Gerenciar Usuario',
            breadcrumb: 'Cadastro/Gerenciar Usuarios',
          },
        },
        {
          path: 'cadastro/gerenciar-perfil',
          name: 'Gerenciar Perfil',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-perfil/ManterPerfilView.vue'
            ),
          meta: {
            title: 'Gerenciar Perfil',
            breadcrumb: 'Cadastro/Gerenciar Perfis',
          },
        },
        {
          path: 'cadastro/gerenciar-grupo',
          name: 'Gerenciar Grupo',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-grupo/ManterGruposView.vue'
            ),
          meta: {
            title: 'Gerenciar Grupo',
            breadcrumb: 'Cadastro/Gerenciar Grupos',
          },
        },
        {
          path: 'cadastro/gerenciar-mantenedores',
          name: 'Gerenciar Mantenedores',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-mantenedores/ManterMantenedoresView.vue'
            ),
          meta: {
            title: 'Gerenciar Mantenedores',
            breadcrumb: 'Cadastro/Gerenciar Mantenedores',
          },
        },
        {
          path: 'cadastro/gerenciar-finalidade',
          name: 'Gerenciar Finalidade',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-finalidade/ManterFinalidadeView.vue'
            ),
          meta: {
            title: 'Gerenciar Finalidade',
            breadcrumb: 'Cadastro/Gerenciar Finalidade',
          },
        },
        {
          path: 'cadastro/gerenciar-oferta-de-ensino',
          name: 'Gerenciar Oferta de Ensino',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-oferta-ensino/ManterOfertaEnsinoView.vue'
            ),
          meta: {
            title: 'Gerenciar Oferta de Ensino',
            breadcrumb: 'Cadastro/Gerenciar Oferta de Ensino',
          },
        },
        {
          path: 'cadastro/gerenciar-unidade-de-ensino',
          name: 'Gerenciar Unidade de Ensino',
          component: () => import( /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-unidade-ensino/ManterUnidadeEnsinoView.vue'),
          meta: {
            title: 'Gerenciar Unidade de Ensino',
            breadcrumb: "Cadastro/Gerenciar Unidade de Ensino",
          },
        },
        {
          path: "relatorio/listar-relatorio-usuarios",
          name: "Listar Relatório de Usuários",
          component: () => import( /* webpackChunkName: "admin" */ "~/views/PostLogin/administracao/relatorios/relatorio-usuario/ListarRelatoriosUsuariosView.vue"),
          meta: {
            title: "Listar Relatório de Usuários",
            breadcrumb: "Relatórios/Listar Relatório de Usuários",
          },
        },  
        {
          path: "relatorio/listar-relatorio-mantenedores",
          name: "Listar Relatório de Mantenedores",
          component: () => import( /* webpackChunkName: "admin" */ "~/views/PostLogin/administracao/relatorios/relatorio-mantenedores/ListarRelatoriosMantenedoresView.vue"),
          meta: {
            title: "Listar Relatório de Mantenedores",
            breadcrumb: "Relatórios/Listar Relatório de Mantenedores",
          },
        },
        {
          path: "relatorio/listar-relatorio-log-mantenedores",
          name: "Listar Relatório de Log de Mantenedores",
          component: () => import( /* webpackChunkName: "admin" */ "~/views/PostLogin/administracao/relatorios/relatorio-log-mantenedores/ListarRelatoriosLogMantenedoresView.vue"),
          meta: {
            title: "Listar Relatório de Log de Mantenedores",
            breadcrumb: "Relatórios/Listar Relatório de Log de Mantenedores",
          },
        },
        {
          path: "relatorio/listar-relatorio-unidades-ensino",
          name: "Listar Relatório de Unidades de Ensino",
          component: () => import( /* webpackChunkName: "admin" */ "~/views/PostLogin/administracao/relatorios/relatorio-unidades-ensino/ListarRelatorioUnidadesEnsinoView.vue"),
          meta: {
            title: "Listar Relatório de Unidades de Ensino",
            breadcrumb: "Relatórios/Listar Relatório de Unidades de Ensino"
          }
        },
        {
          path: 'configuracao/gerenciar-faq',
          name: 'Gerenciar FAQ',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '~/views/PostLogin/administracao/gerenciar-faq/ManterFAQView.vue'
            ),
          meta: {
            title: 'Gerenciar FAQ',
            breadcrumb: 'Configuração/Gerenciar FAQ',
          },
        },
        {
          path: 'configuracao/configurar-pagina-inicial',
          name: 'Configurar Página Inicial',
          component: () =>
            import(
              /* webpackChunkName: "example-customize-landing-page" */ 'views/Examples/CustomizeLandingPage.vue'
            ),
          meta: {
            title: 'Configurar Página Inicial',
            breadcrumb: 'Configuração/Configurar Página Inicial',
          },
        },
      ],
    },
    {
      path: '/exemplos',
      component: ExamplesLayout,
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: '/',
          redirect: {
            name: 'examplelist',
          },
        },
        {
          path: 'listar',
          name: 'examplelist',
          component: () =>
            import(
              /* webpackChunkName: "example-list" */ 'views/Examples/List.vue'
            ),
          meta: {
            title: 'Exemplo de Listar',
          },
        },
        {
          path: 'registrar',
          name: 'exampleedit',
          component: () =>
            import(
              /* webpackChunkName: "example-register" */ 'views/Examples/Register.vue'
            ),
          meta: {
            title: 'Exemplo de Editar',
          },
        },
        {
          path: 'configuracoes',
          name: 'examplesettings',
          component: () =>
            import(
              /* webpackChunkName: "example-settings" */ 'views/Examples/Settings.vue'
            ),
          meta: {
            title: 'Exemplo de Configurações',
          },
        },
        {
          path: 'permissoes',
          name: 'examplepermissions',
          component: () =>
            import(
              /* webpackChunkName: "example-permissions" */ 'views/Examples/Permissions.vue'
            ),
          meta: {
            title: 'Exemplo de Permissões',
          },
        },
        {
          path: 'enviararquivos',
          name: 'examplefilesupload',
          component: () =>
            import(
              /* webpackChunkName: "example-customize-landing-page" */ 'views/Examples/Upload.vue'
            ),
          meta: {
            title: 'Enviar Arquivos',
          },
        },
        {
          path: 'perfis',
          name: 'exampleprofiles',
          component: () =>
            import(
              /* webpackChunkName: "example-profiles-page" */ 'views/Examples/Permissions/Profiles.vue'
            ),
          meta: {
            title: 'Perfis',
          },
        },
        {
          path: 'permissoes2',
          name: 'examplepermissions2',
          component: () =>
            import(
              /* webpackChunkName: "example-permissions-2" */ 'views/Examples/Permissions/Permissions.vue'
            ),
          meta: {
            title: 'Permissões',
          },
        },
      ],
    }
  ];
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });

  router.beforeEach((to, from, next) => {
    function updateTitleAndMetadata() {
      // This goes through the matched routes from last to first, finding the closest route with a title.
      // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
      // `/nested`'s will be chosen.
      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

      // Find the nearest route element with meta tags.
      const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

      const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

      // If a route with a title was found, set the document (page) title to that value.
      if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
      } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
      }

      // Remove any stale meta tags from the document using the key attribute we set below.
      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el));

      // Skip rendering meta tags if there are none.
      if (!nearestWithMeta) return next();

      // Turn the meta tag definitions into actual elements in the head.
      nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
      })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
    }

    function authorize() {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        const user = store.getters[`${AUTH_NAMESPACE}/${USER}`]
        if (user) {
          next()
          return
        }
        next({
          path: '/login'
        })
      } else {
        next()
      }
    }

    updateTitleAndMetadata();

    authorize();
  });

  router.afterEach((to) => {
    if (to.meta.showPageLoading) {
      router.app.showPageLoading();
    }

    if (to.meta.showBackground) {
      router.app.showDefaultBackgroundImage();
    } else {
      router.app.hideDefaultBackgroundImage();
    }
  })

  return router
}


export default routerFactory