import configurations from 'commons/configurations'

export const PRE_LOGIN_LAYOUT_NAMESPACE = 'preLoginLayout'

// mutations (privadas)
const MUTATE_BACKGROUND_IMAGE = "mutateBackgroundImage"
const MUTATE_BACKGROUND_IMAGE_VISIBILITY = "mutateBackgroundImageVisibility"
const DEFAULT_BACKGROUND_IMAGE = configurations.layout.preLogin.backgroundImageUri;

// actions (publicas)
export const UPDATE_BACKGROUND_IMAGE = 'updateBackgroundImage'
export const UPDATE_BACKGROUND_IMAGE_VISIBILITY = 'updateBackgroundImageVisibility'
export const CLEAR = 'clear'

// getters (publicos)
export const BACKGROUND_IMAGE = 'bagroundImage'
export const BACKGROUND_IMAGE_VISIBILITY = 'backgroundImageVisibility'

export default {
  namespaced: true,
  state: () => ({
    bagroundImage: DEFAULT_BACKGROUND_IMAGE,
    isBackgroundImageVisible: true,
  }),
  mutations: {
    [MUTATE_BACKGROUND_IMAGE](state, bagroundImage) {
      state.bagroundImage = bagroundImage
    },
    [MUTATE_BACKGROUND_IMAGE_VISIBILITY](state, visible) {
      state.isBackgroundImageVisible = visible
    },
  },
  actions: {
    async [UPDATE_BACKGROUND_IMAGE]({commit}, bagroundImage) {
      commit(MUTATE_BACKGROUND_IMAGE, (bagroundImage || DEFAULT_BACKGROUND_IMAGE));
    },
    async [UPDATE_BACKGROUND_IMAGE_VISIBILITY]({commit}, visible) {
      commit(MUTATE_BACKGROUND_IMAGE_VISIBILITY, (typeof visible === 'boolean' ? visible : true));
    },
    async [CLEAR]({commit}) {
      commit(MUTATE_BACKGROUND_IMAGE, DEFAULT_BACKGROUND_IMAGE);
      commit(MUTATE_BACKGROUND_IMAGE_VISIBILITY, true);
    }
  },
  getters: {
    [BACKGROUND_IMAGE]: state => state.bagroundImage,
    [BACKGROUND_IMAGE_VISIBILITY]: state => state.isBackgroundImageVisible,
  }
}