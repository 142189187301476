import { Ability } from '@casl/ability';
import { ABILITIES } from "store/modules/auth";

export function ConvertPermissionsIntoAbilities(permissions) {
  if(!Array.isArray(permissions)) {
    return [];
  }

  let abilities = [];
  let permissionName = undefined;
  for(let permission of permissions) {
    permissionName = permission.nome.toLocaleLowerCase().trim();
    
    // professor
    if(permissionName === 'cadastrar professores') {
      abilities.push({ action: 'create', subject: 'professor' });
      abilities.push({ action: 'edit', subject: 'professor' });
      abilities.push({ action: 'delete', subject: 'professor' });
    } else if(permissionName === 'listagem de professores') {
      abilities.push({ action: 'read', subject: 'professor' });
    } else if(permissionName === 'relatórios de professores') {
      abilities.push({ action: 'report', subject: 'professor' });
    }

    // student
    else if(permissionName === 'cadastrar alunos') {
      abilities.push({ action: 'create', subject: 'student' });
      abilities.push({ action: 'edit', subject: 'student' });
      abilities.push({ action: 'delete', subject: 'student' });
    } else if(permissionName === 'listagem de alunos') {
      abilities.push({ action: 'read', subject: 'student' });
    } else if(permissionName === 'relatórios de alunos') {
      abilities.push({ action: 'report', subject: 'student' });
    }
  }

  return abilities;
}

export default new Ability(JSON.parse(atob(localStorage.getItem(ABILITIES) || '') || '[]') || []);