export class Dialog {
  constructor() {
    this.visible = false;
    this.edit = false;
    this.content = {};
  }
  open(content, edit) {
    this.visible = true;
    this.edit = edit;
    this.content = content;
  }
  close() {
    this.visible = false;
  }
  static Build() {
    return new Dialog({ visible: false, edit: false, content: {} });
  }
}

export class DialogProps {
  constructor(t) {
    this.visible = {
      type: Boolean,
      default: false,
    };
    this.edit = {
      type: Boolean,
      default: false,
    };
    this.content = {
      type: Object,
      default: () => new t(),
    };
  }
  static Build() {
    return new DialogProps(Object);
  }
}

export class NavigationMapItem {
  constructor({
    icon = '',
    text = '',
    url = '',
    permission = '',
    next = '',
    complete = false,
  }) {
    this.icon = icon;
    this.text = text;
    this.url = url;
    this.next = next;
    this.permission = permission;
    this.complete = complete;
  }
  static Build() {
    return new NavigationMapItem({
      icon: '',
      text: '',
      url: '',
      permission: '',
      next: '',
      complete: false,
    });
  }
}

export class Anexo {
  constructor({ descricao = null, path = null }) {
    this.descricao = descricao;
    this.path = path;
  }
  static Build() {
    return new Anexo({
      descricao: null,
      path: null,
    });
  }
}

export class Grupo {
  constructor({
    id = null,
    nome = null,
    nomeDeExibicao = null,
    permissoes = Array(),
  }) {
    this.id = id;
    this.nome = nome;
    this.nomeDeExibicao = nomeDeExibicao;
    this.permissoes = permissoes;
  }
  static Build() {
    return new Grupo({
      id: null,
      nome: null,
      nomeDeExibicao: null,
      permissoes: Array(),
    });
  }
  format() {
    this.permissoes = this.permissoes.map((p) => p.id);
    return this;
  }
}
export class Mantenedores {
  constructor({
    id = null,
    nome = null,
    cpfCnpj = null,
    ativo = null,
    qualificacaoId = null,
    qualificacaoNome = null
  }) {
    this.id = id;
    this.nome = nome;
    this.cpfCnpj = cpfCnpj;
    this.ativo = ativo;
    this.qualificacaoId = qualificacaoId;
    this.qualificacaoNome = qualificacaoNome;
  }

  static Build() {
    return new Mantenedores({
      id: null,
      nome: null,
      cpfCnpj: null,
      ativo: null,
      qualificacaoId: null,
      qualificacaoNome: null
    });
  }

  format() {
    return {
      mantenedorId: this.id,
      nome: this.nome,
      cpfCnpj: this.cpfCnpj,
      ativo: this.ativo,
      qualificacaoId: this.qualificacaoId,
      qualificacaoNome: this.qualificacaoNome
    };
  }
}

export class Perfil {
  constructor({
    id = null,
    nome = null,
    grupos = Array(),
    usuarioResponsavel = null,
    administrador = true,
  }) {
    this.id = id;
    this.nome = nome;
    this.grupos = grupos;
    this.usuarioResponsavel = usuarioResponsavel;
    this.descricao = nome;
    this.administrador = administrador;
  }

  static Build() {
    return new Perfil({
      id: null,
      nome: null,
      grupos: Array(),
      usuarioResponsavel: null,
      descricao: null,
      administrador: true,
    });
  }

  format() {
    return new Perfil({
      perfilId: this.id,
      grupos: this.grupos.map((p) => p.id),
    });
  }
}

export class Usuario {
  constructor({
    id = null,
    ativo = null,
    nome = null,
    email = null,
    cpf = null,
    grupos = Array(),
    perfis = Array(),
    usuarioSadRh = false
  }) {
    this.id = id;
    this.ativo = ativo;
    this.nome = nome;
    this.email = email;
    this.cpf = cpf;
    this.grupos = grupos;
    this.perfis = perfis;
    this.usuarioSadRh = usuarioSadRh;
    this.permitirAlterarEmail = !email || !usuarioSadRh;
  }
  static Build() {
    return new Usuario({
      id: null,
      ativo: true,
      nome: null,
      email: null,
      cpf: null,
      grupos: Array(),
      perfis: Array(),
      usuarioSadRh: false,
      permitirAlterarEmail: true
    });
  }
  format() {
    this.usuarioId = this.id;
    this.perfis = this.perfis.map((p) => p.id);
    return this;
  }
}

export class TrocarSenha {
  constructor({
    token = null,
    nomeDeUsuario = null,
    primeiroAcesso = null,
    visible = null,
  }) {
    this.token = token;
    this.nomeDeUsuario = nomeDeUsuario;
    this.primeiroAcesso = primeiroAcesso;
    this.visible = visible;
  }
  static Build() {
    return new TrocarSenha({
      token: null,
      nomeDeUsuario: null,
      primeiroAcesso: null,
      visible: null,
    });
  }
}
export class Termo {
  constructor({
    id = null,
    nome = null,
    tipoDoTermo = null,
    dataInicio = null,
    dataFim = null,
    ativo = null,
    html = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.tipoDoTermo = tipoDoTermo;
    this.dataInicio = dataInicio;
    this.dataFim = dataFim;
    this.ativo = ativo;
    this.html = html;
  }
  static Build() {
    return new Termo({
      id: null,
      nome: null,
      tipoDoTermo: null,
      dataInicio: null,
      dataFim: null,
      ativo: null,
      html: null,
    });
  }
  format() {
    this.dataInicio = new Date(this.dataInicio).toLocaleDateString();
    this.dataFim = new Date(this.dataFim).toLocaleDateString();
    return this;
  }
}
export class Finalidade {
  constructor({ id = null, nome = null, ativo = null }) {
    this.id = id;
    this.nome = nome;
    this.ativo = ativo;
  }

  static Build() {
    return new Finalidade({
      id: null,
      nome: null,
      ativo: null,
    });
  }

  format() {
    return {
      finalidadeId: this.id,
      nome: this.nome,
      ativo: this.ativo,
    };
  }
}

export class OfertaDeEnsino {
  constructor({
    id = null,
    etapa = Array(),
    modalidade = Array(),
    nome = null,
    ativo = null,
  }) {
    this.id = id;
    this.etapa = etapa;
    this.modalidade = modalidade;
    this.nome = nome;
    this.ativo = ativo;
  }
  static Build() {
    return new OfertaDeEnsino({
      id: null,
      etapa: Array(),
      modalidade: Array(),
      nome: null,
      ativo: null,
    });
  }
  format() {
    this.ofertaEnsinoId = this.id;
    this.etapaId = this.etapa.map((p) => p.id);
    this.modalidadeId = this.modalidade.map((p) => p.id);
    return this;
  }
}

export class FAQ {
  constructor({
    ativo = null,
    id = null,
    palavrasChave = Array(),
    pergunta = null,
    posicao = null,
    resposta = null,
  }) {
    this.ativo = ativo;
    this.id = id;
    this.palavrasChave = palavrasChave;
    this.pergunta = pergunta;
    this.posicao = posicao;
    this.resposta = resposta;
  }
  static Build() {
    return new FAQ({
      ativo: null,
      id: null,
      palavrasChave: Array(),
      pergunta: null,
      posicao: null,
      resposta: null,
    });
  }
}

export class UnidadeDeEnsino {
  constructor({
    id = null,
    codigoMec = null,
    codigo = null,
    nomeUnidadeEnsino = null,
    cnpj = null,
    nomeEmpresarial = null,
    cep = null,
    logradouro = null,
    numero = null,
    complemento = null,
    bairro = null,
    municipio = { id: null },
    uf = 'PE',
    dependenciaAdministrativa = { id: null },
    situacaoFuncionamento = { id: null },
    unidadeRegional = { id: null },
    localizacao = { id: null },
    identificacoes = Array(),
    mantenedores = Array(),
    relacaoMantenedores = Array(),
    portarias = Array(),
  }) {
    this.id = id;
    this.codigoMec = codigoMec;
    this.codigo = codigo;
    this.nomeUnidadeEnsino = nomeUnidadeEnsino;
    this.cnpj = cnpj;
    this.nomeEmpresarial = nomeEmpresarial;
    this.cep = cep;
    this.logradouro = logradouro;
    this.numero = numero;
    this.complemento = complemento;
    this.bairro = bairro;
    this.municipio = municipio;
    this.uf = uf;
    this.situacaoFuncionamento = situacaoFuncionamento;
    this.dependenciaAdministrativa = dependenciaAdministrativa;
    this.unidadeRegional = unidadeRegional;
    this.localizacao = localizacao;
    this.identificacoes = identificacoes;
    this.mantenedores = mantenedores;
    this.relacaoMantenedores = relacaoMantenedores;
    this.portarias = portarias;
  }
  static Build() {
    return new UnidadeDeEnsino({
      id: null,
      codigoMec: null,
      codigo: null,
      nomeUnidadeEnsino: null,
      cnpj: null,
      nomeEmpresarial: null,
      cep: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      municipio: { id: null },
      uf: 'PE',
      dependenciaAdministrativa: { id: null },
      situacaoFuncionamento: { id: null },
      unidadeRegional: { id: null },
      localizacao: { id: null },
      identificacoes: Array(),
      mantenedores: Array(),
      relacaoMantenedores: Array(),
      portarias: Array(),
    });
  }
  format() {
    return this;
  }
  formatInverso() {
    return this;
  }
}


export class MantenedoresUnidadeDeEnsino {
  constructor({
    id = null,
    nome = null,
    cpf = null,
    ativo = null,
    qualificacao = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.cpf = cpf;
    this.ativo = ativo;
    this.qualificacao = qualificacao;
  }

  static Build() {
    return new Mantenedores({
      id: null,
      nome: null,
      cpf: null,
      ativo: null,
      qualificacao: null,
    });
  }
}

export class FinalidadeUnidadeDeEnsino {
  constructor({
    id = null,
    nome = null,
    ativo = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.ativo = ativo;
  }

  static Build() {
    return new Finalidade({
      id: null,
      nome: null,
      ativo: null,
    });
  }
}
export class Portaria {
  constructor({
    numero = null,
    dataDo = null,
    portariaFinalidade = { nome: null },
    portariaOfertaEnsino = { nome: null },
  }) {
    this.numero = numero;
    this.dataDo = dataDo;
    this.portariaFinalidade = portariaFinalidade;
    this.portariaOfertaEnsino = portariaOfertaEnsino;
  }
}

export class GerenciaRegional {
  constructor({ id = null, nome = '', codigo = '' }) {
    this.id = id;
    this.nome = nome;
    this.codigo = codigo;
  }
}

export class Municipio {
  constructor({
    nome = '',
    gerenciaRgionalId = '',
    gerenciaRegional = {},
    municipioCodigo = '',
    id = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.gerenciaRegional = gerenciaRegional;
    this.gerenciaRegionalId = gerenciaRgionalId;
    this.municipioCodigo = municipioCodigo;
  }
}
