const baseURL = '/Administracao';

const service = {
  methods: {
    $obterTermo(params) {
      return this.$api().get(`${baseURL}/Termos/Lgpd`, params);
    },
    $aceitarTermo(body) {
      return this.$api().post(`${baseURL}/Termos/TermoDeAceiteDeLgpd`, body);
    },
    $obterEtapas(params) {
      return this.$api().get(`${baseURL}/etapas`, params);
    },
    $unidadeEnsinoCredenciamento(params) {
      return this.$api().get(
        `${baseURL}/unidade-ensino-credenciamento`,
        params
      );
    },
    $municipioCredenciamento(params) {
      return this.$api().get(`${baseURL}/municipio-credenciamento`, params);
    },
    $dependenciaAdministrativaCredenciamento(params) {
      return this.$api().get(
        `${baseURL}/dependencia-administrativa-credenciamento`,
        params
      );
    },
    $portariaCredenciamento(params) {
      return this.$api().get(`${baseURL}/portaria-credenciamento`, params);
    },
    $localizacaoCredenciamento(params) {
      return this.$api().get(`${baseURL}/localizacao-credenciamento`, params);
    },
    $ofertaEnsinoCredenciamento(params) {
      return this.$api().get(
        `${baseURL}/ofertas-de-ensino-credenciamento`,
        params
      );
    },
    $carregarMunicipios(params) {
      return this.$api().get(`${baseURL}/municipio-credenciamento`, params);
    },
    $carregarGerenciasRegionais(params) {
      return this.$api().get(`${baseURL}/gerencia-regional-credenciamento`, params)
    },
    $finalidadesCredenciamento(params){ 
      return this.$api().get(`${baseURL}/finalidades-credenciamento`, params);
    }
  },
};

export default service;
