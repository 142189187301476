<template>
    <div class="d-flex col" :class="`col-${cols}`" @click="$emit('click')">
      <label :class="className" tabindex="0" role="checkbox">
        <input
          type="checkbox"
          class="v-switch-input"
          tabindex="-1"
          :checked="value"
          :disabled="disabled"
          @click="mudar"
        />
        <span class="label">{{ label }}</span>
        <div class="v-switch-core" :style="coreStyle">
          <div
            v-if="domRenderizado && showBaloon"
            class="v-switch-button"
            :style="buttonStyle"
          ></div>
        </div>
        <template>
          <span class="v-switch-label v-left" :style="labelStyle" v-if="value">
            <slot name="checked">
              <template>{{ labelChecked }}</template>
            </slot>
          </span>
          <span
            class="v-switch-label"
            :class="showBaloon ? 'v-right' : ' v-left'"
            :style="labelStyle"
            v-else
          >
            <slot name="unchecked">
              <template>{{ labelUnchecked }}</template>
            </slot>
          </span>
        </template>
      </label>
    </div>
  </template>
  
  <script>
  export default {
    name: "SwitchButtonComponent",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      labelChecked: {
        type: String,
        default: "Sim",
      },
      labelUnchecked: {
        type: String,
        default: "Não",
      },
      showBaloon: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
      },
      cols: {
        type: Number,
        default: 6,
      },
    },
    data: () => ({
      domRenderizado: false,
    }),
    computed: {
      className() {
        let { toggled, disabled } = this;
        return ["vue-js-switch", { toggled, disabled }];
      },
      coreStyle() {
        return {
          height: `4em`,
          backgroundColor: `#f5f5f5`,
          borderRadius: `4px`,
          border: `1px solid`,
          opacity: `0.5`,
          width: `100%`,
        };
      },
      buttonStyle() {
        const width = `${
          document.querySelector(".v-switch-core")?.clientWidth - 30
        }px`;
        return {
          width: `2em`,
          height: `2em`,
          transition: `transform 300ms`,
          transform: `translate(${this.value ? width : `10px`}, 10px)`,
        };
      },
      labelStyle() {
        return {
          lineHeight: `43px`,
          fontSize: `16px`,
          transition: `transform 300ms`,
          transform: `translate(${
            this.value && this.showBaloon ? "0.1em" : "-0.1em"
          }, 0px)`,
        };
      },
    },
    watch: {
      value(value) {
        this.$emit("input", !!value);
      },
    },
    methods: {
      mudar() {
        if (this.disabled) {
          return;
        }
        this.$emit("input", !this.value);
      },
    },
    mounted() {
      this.domRenderizado = true;
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .vue-js-switch {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    user-select: none;
    font-size: 10px;
    width: 100%;
    cursor: pointer;
    .v-switch-input {
      opacity: 0;
      position: absolute;
      width: 1px;
      height: 1px;
    }
    .v-switch-label {
      position: absolute;
      top: 0;
      z-index: 1;
      &.v-left {
        left: 0.8em;
      }
      &.v-right {
        right: 0.8em;
      }
    }
    .v-switch-core {
      display: block;
      position: relative;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: border-color 0.3s, background-color 0.3s;
      user-select: none;
      .v-switch-button {
        display: block;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        border-radius: 100%;
        background-color: #000;
        opacity: 1 !important;
        z-index: 2;
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
    .label {
      position: absolute;
      top: -0.7em;
      left: 1em;
      z-index: 1;
      background: #f5f5f5;
      padding: 1px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  </style>
  