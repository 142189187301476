<template>
  <div>
    <v-dialog id="app-updater" v-model="hasUpdate" persistent width="344">
      <v-card class="mx-auto card-app-update" color="transparent">
        <div class="bg-content"></div>
        <div class="bg-img"></div>
        <v-img
          class="
            white--text
            align-end
            card-cover
            d-flex
            align-center
            justify-center
          "
          src="/img/foguete.png"
        >
        </v-img>

        <v-card-title class="text-center justify-center">
          Nova Versão
        </v-card-title>

        <v-card-subtitle class="mt-1 text-center">
          O <strong class="light-blue--text text--darken-4" v-text="appName"></strong> atualizou e agora está mais rápido e com novas funcionalidades!
        </v-card-subtitle>

        <v-card-actions class="justify-center pb-8">
          <v-btn color="success" rounded outlined text @click.prevent="update">
            <v-icon>mdi-lightning-bolt</v-icon> Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AppUpdater",
  props: {
    appName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    registration: null,
    hasUpdate: false,
    updating: false,
  }),
  mounted() {
    try {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.updating) {
          return;
        }
        this.updating = true;
        window.location.reload();
      });

      document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, {
        once: true,
      });
    } catch {
      console.warn('Unable to add event listener!');
    }
  },
  methods: {
    appUpdateUI(e) {
      this.registration = e.detail;
      this.hasUpdate = true;
    },
    update() {
      this.hasUpdate = false;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-dialog.v-dialog--persistent {
  box-shadow: none;
}

.bg-content {
  background-color: #fff;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  z-index: -1;
}

.bg-img {
  border-radius: 4px;
  background: #0045aa;
  background: linear-gradient(to bottom left, #0045aa, #80b4ff);
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  height: 180px;
}
</style>