const urlRelatorios = "/Relatorios";

const service = {
  methods: {
    $obterRelatorioUnidadesEnsinoAnalitico(parametros) {
      return this.$api().get(`${urlRelatorios}/RelatorioUnidadesEnsinoAnalitico`, parametros);
    },
    $obterRelatorioUnidadesEnsinoAnaliticoPdf(parametros) {
      return this.$api().get(`${urlRelatorios}/RelatorioUnidadesEnsinoAnaliticoPdf`, parametros);
    },
    $obterRelatorioUnidadesEnsinoAnaliticoCvs(parametros) {
      return this.$api().get(`${urlRelatorios}/RelatorioUnidadesEnsinoAnaliticoCsv`, parametros);
    },

    $obterRelatorioUnidadesEnsinoSintetico(parametros) {
      return this.$api().get(`${urlRelatorios}/RelatorioUnidadesEnsinoSintetico`, parametros);
    },
    $obterRelatorioUnidadesEnsinoSinteticoPdf(parametros) {
      return this.$api().get(`${urlRelatorios}/RelatorioUnidadesEnsinoSinteticoPdf`, parametros);
    },
    $obterRelatorioUnidadesEnsinoSinteticoCvs(parametros) {
      return this.$api().get(`${urlRelatorios}/RelatorioUnidadesEnsinoSinteticoCsv`, parametros);
    }
  }
};

export default service;
