const baseURL = "/Administracao/grupos";

const service = {
  methods: {
        $buscarGrupos(params) {
          return this.$api().get(`${baseURL}`, params);
        },
        $buscarTodasPermissoes(params) {
            return this.$api().get(`${baseURL}/Permissoes`, params);
        },
        $visualizarGrupos(id) {
            return this.$api().get(`${baseURL}/${id}`);
        },
        $cadastrarGrupo(params) {
            return this.$api().post(`${baseURL}`, params);
        },
        $editarGrupo(body) {
            return this.$api().put(`${baseURL}`, body);
        },
        $inativarAtivarGrupo(body) {
            return this.$api().patch(`${baseURL}/alterarStatus`, body);
        },
  },
};

export default service;
