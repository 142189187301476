const baseURL = "/v1/Acesso";

const service = {
  methods: {
    $obterMenu(nomeDeUsuario) {
      return this.$api().get(
        `${baseURL}/Usuario/${encodeURI(nomeDeUsuario)}/Menu`
      );
    },
    $obterPermissoesDoUsuario(nomeDeUsuario) {
      return this.$api().get(
        `${baseURL}/Usuario/${encodeURI(nomeDeUsuario)}/Permissoes`
      );
    },
    $mudarSenha(body) {
      return this.$api().post(`${baseURL}/SolicitarAlterarSenha`, body);
    },
  },
};

  export default service;