<template>
    <v-col cols="12" :sm="cols">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            readonly
            outlined
            dense
            v-model="computedDateFormattedCompleted"
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            :append-icon="removeIcon ? '' : 'mdi-calendar'"
            :label="label"
            :dataEdicao="dataEdicao"
            :rules="required ? [(v) => $required(v), (v) => $validarDate(v)] : []"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dataAtual"
          no-title
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </template>
  
  <script>
  export default {
    name: "DataPickerComponent",
    props: {
      label: {
        type: String,
      },
      dataEdicao: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      removeIcon: {
        type: Boolean,
        default: false,
      },
      cols: {
        type: [Number, String],
        default: 6,
      },
    },
    data: () => ({
      menu: false,
      dataAtual: "",
      preenchimentoObrigatorio: true,
    }),
    computed: {
      computedDateFormattedCompleted() {
        if (this.dataAtual !== "") {
          return this.formatDateDiaMesAno(this.dataAtual);
        } else {
          return this.dataEdicao;
        }
      },
    },
    methods: {
      formatDateDiaMesAno(dataAtual) {
        if (!dataAtual) return null;
  
        const [year, month, day] = dataAtual.split("-");
        return `${day.substr(0, 2)}/${month.substr(0, 2)}/${year.substr(0, 4)}`;
      },
//       formatDateDiaMesAno(dataAtual) {
//   if (!dataAtual) return null;

//   const [year, month, day] = dataAtual.split("-");
//   const formattedDate = new Date(`${year}-${month}-${day}T00:00:00`);
//   return formattedDate.toISOString();
// },
      clearFields() {
        this.preenchimentoObrigatorio = false;
        this.dataAtual = "";
      },
    },
    watch: {
      dataAtual(val) {
        this.$emit("input", val);
      },
    },
  };
  </script>
  