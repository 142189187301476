<template>
    <v-col>
      <v-row class="d-block text-center btn-group">
        <v-col cols="12">
          <v-btn
            color="primary"
            :disabled="disableAction"
            @click="$emit('click')"
          >
            <v-icon v-if="actionIcon">{{ actionIcon }}</v-icon> {{ actionText }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableCancel"
            @click="$emit('cancel')"
            outlined
            class="ml-2"
          >
            <v-icon v-if="cancelIcon">{{ cancelIcon }}</v-icon> {{ cancelText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </template>
  
  <script>
  export default {
    name: "ButtonsComponent",
    props: {
      actionText: {
        type: String,
        default: "Salvar",
      },
      cancelText: {
        type: String,
        default: "Cancelar",
      },
      disableAction: {
        type: Boolean,
        default: false,
      },
      disableCancel: {
        type: Boolean,
        default: false,
      },
      actionIcon: {
        type: String,
        default: null,
      },
      cancelIcon: {
        type: String,
        default: null,
      },
    },
  };
  </script>