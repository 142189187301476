const baseURL = "/Administracao/ofertas-de-ensino";

const service = {
  methods: {
        $buscarOfertasEnsinos(params) {
          return this.$api().get(`${baseURL}`, params);
        },
        $visualizarOfertasEnsinos(id) {
            return this.$api().get(`${baseURL}/${id}`);
        },
        $cadastrarOfertasEnsino(params) {
            return this.$api().post(`${baseURL}`, params);
        },
        $editarOfertasEnsino(body) {
            return this.$api().put(`${baseURL}`, body);
        },
        $inativarAtivarOfertasEnsino(body) {
            return this.$api().put(`${baseURL}/alterarStatus`, body);
        },
  },
};

export default service;
