const credenciamentoURL = '/Credenciamento';

const service = {
  methods: {
    $obterCep(params) {
      return this.$api().get(`${credenciamentoURL}/cep`, params);
    },
    $obterMunicipio(params) {
      return this.$api().get(`${credenciamentoURL}/municipio`, params);
    },
    $obterModalidades(params) {
      return this.$api().get(`${credenciamentoURL}/modalidades`, params);
    },
    $obterPortaria(params) {
      return this.$api().get(`${credenciamentoURL}/portaria`, params);
    },
    $buscarFinalidadesAtivas(params) {
      return this.$api().get(`${credenciamentoURL}/finalidades`, params);
    },
    $obterLocalizacao(params) {
      return this.$api().get(`${credenciamentoURL}/localizacao`, params);
    },
    $obterUnidadeRegional(params) {
      return this.$api().get(`${credenciamentoURL}/gerencia-regional`, params);
    },
    $consultarCredenciamentoUnidadeEnsino(params) {
      return this.$api().get(`${credenciamentoURL}/unidade-ensino`, params);
    },
    $obterCredenciamentoUnidadeEnsino(params) {
      return this.$api().get(`${credenciamentoURL}/obter-unidade-ensino`, params);
    }
  }
};

export default service;
