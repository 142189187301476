import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"30%"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"pa-10 not-margin"},[_c(VForm,{ref:"form"},[_c(VRow,[_c(VCol,{staticClass:"text-center"},[_c('img',{attrs:{"src":"/img/logo.svg","alt":"brasão-home-64px"}}),_c('h1',{staticClass:"subtitle-1 blue--text text--darken-4 mt-5"},[_vm._v(" "+_vm._s(_vm.content.primeiroAcesso ? "Altere a senha para acessar as funcionalidades do SCAE" : "Alterar senha SCAE")+" ")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(!_vm.content.primeiroAcesso)?_c(VTextField,{attrs:{"solo":"","placeholder":"Senha Atual","hide-details":"auto","type":_vm.mostrarSenhaAtual ? 'text' : 'password',"append-icon":_vm.mostrarSenhaAtual ? 'mdi-eye-off' : 'mdi-eye',"rules":[function (v) { return _vm.$required(v); }, function (v) { return _vm.$max(v, 8); }, function (v) { return _vm.$min(v, 6); }],"maxlength":8},on:{"click:append":function($event){_vm.mostrarSenhaAtual = !_vm.mostrarSenhaAtual}},model:{value:(_vm.form.senhaAtual),callback:function ($$v) {_vm.$set(_vm.form, "senhaAtual", $$v)},expression:"form.senhaAtual"}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"solo":"","placeholder":"Nova Senha","hide-details":"auto","type":_vm.mostrarNovaSenha ? 'text' : 'password',"append-icon":_vm.mostrarNovaSenha ? 'mdi-eye-off' : 'mdi-eye',"rules":[
                function (v) { return _vm.$required(v, 'Senha é obrigatória'); },
                function (v) { return _vm.$max(v, 8); },
                function (v) { return _vm.$min(v, 6); },
                function (v) { return !(v == _vm.form.senhaAtual) ||
                  'A nova senha não pode ser igual a anterior!'; } ],"maxlength":8},on:{"click:append":function($event){_vm.mostrarNovaSenha = !_vm.mostrarNovaSenha}},model:{value:(_vm.form.novaSenha),callback:function ($$v) {_vm.$set(_vm.form, "novaSenha", $$v)},expression:"form.novaSenha"}})],1),_c(VCol,{attrs:{"cols":"12"}},[(!_vm.content.primeiroAcesso)?_c(VTextField,{attrs:{"solo":"","placeholder":"Confirmar Senha","hide-details":"auto","type":_vm.mostrarConfirmarSenha ? 'text' : 'password',"append-icon":_vm.mostrarConfirmarSenha ? 'mdi-eye-off' : 'mdi-eye',"rules":[
                function (v) { return _vm.$required(v); },
                function (v) { return _vm.$max(v, 8); },
                function (v) { return _vm.$min(v, 6); },
                function (v) { return v == _vm.form.novaSenha ||
                  'A nova senha e a confirmação de senha devem ser iguais!'; } ],"maxlength":8},on:{"click:append":function($event){_vm.mostrarConfirmarSenha = !_vm.mostrarConfirmarSenha}},model:{value:(_vm.form.confirmarSenha),callback:function ($$v) {_vm.$set(_vm.form, "confirmarSenha", $$v)},expression:"form.confirmarSenha"}}):_vm._e()],1)],1),_c(VRow,{staticClass:"pt-10"},[_c(VCol,{attrs:{"cols":_vm.content.primeiroAcesso ? 12 : 6}},[_c(VBtn,{attrs:{"block":"","color":"success"},on:{"click":function($event){return _vm.salvar()}}},[_vm._v(" Mudar Senha ")])],1),(!_vm.content.primeiroAcesso)?_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"block":"","color":"error"},on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" Fechar ")])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }