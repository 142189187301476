import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"id":"app-updater","persistent":"","width":"344"},model:{value:(_vm.hasUpdate),callback:function ($$v) {_vm.hasUpdate=$$v},expression:"hasUpdate"}},[_c(VCard,{staticClass:"mx-auto card-app-update",attrs:{"color":"transparent"}},[_c('div',{staticClass:"bg-content"}),_c('div',{staticClass:"bg-img"}),_c(VImg,{staticClass:"white--text align-end card-cover d-flex align-center justify-center",attrs:{"src":"/img/foguete.png"}}),_c(VCardTitle,{staticClass:"text-center justify-center"},[_vm._v(" Nova Versão ")]),_c(VCardSubtitle,{staticClass:"mt-1 text-center"},[_vm._v(" O "),_c('strong',{staticClass:"light-blue--text text--darken-4",domProps:{"textContent":_vm._s(_vm.appName)}}),_vm._v(" atualizou e agora está mais rápido e com novas funcionalidades! ")]),_c(VCardActions,{staticClass:"justify-center pb-8"},[_c(VBtn,{attrs:{"color":"success","rounded":"","outlined":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-lightning-bolt")]),_vm._v(" Atualizar ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }