import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentPageHasMenu)?_c(VNavigationDrawer,{attrs:{"id":"sidebar","app":"","floating":"","fixed":"","clipped":"","permanent":_vm.permanentSidebar,"color":_vm.sidebarColor,"src":_vm.sidebarBackgroundImage},on:{"input":_vm.onDrawerToggled},model:{value:(_vm.currentDrawer),callback:function ($$v) {_vm.currentDrawer=$$v},expression:"currentDrawer"}},[(!_vm.permanentSidebar)?_c(VAvatar,{staticClass:"d-block text-center mx-auto mt-4",attrs:{"size":"36"}},[_c('img',{staticClass:"app-icon",attrs:{"alt":"Brasão de Pernambuco","src":"/img/logo-SEE-branca-1-2.png"}})]):_vm._e(),_c(VDivider,{staticClass:"mt-16 border-0"}),_c(VList,{attrs:{"shaped":""}},[_c(VSubheader,{domProps:{"textContent":_vm._s(_vm.currentPageMenu.displayName)}}),_vm._l((_vm.currentPageMenu.itens),function(item,i){return _c(VListItem,{key:i,attrs:{"link":"","to":item.endereco}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.displayName)}})],1)],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }