const mixin = {
    methods: {
      $required(value, message = "Campo obrigatório") {
        if (typeof value === "string" && value.length) {
          return true;
        }
        if (typeof value === "number" && value) {
          return true;
        }
        if (typeof value === "boolean") {
          return true;
        }
        return message;
      },
      $min(value, min) {
        if (typeof value === "string" && value.trim().length >= min) {
          return true;
        }
        if (typeof value === "number" && value >= min) {
          return true;
        }
        return `Esse campo deve ter pelo menos ${min} caracteres`;
      },
      $max(value, max) {
        if (typeof value === "string" && value.trim().length <= max) {
          return true;
        }
        if (typeof value === "number" && value <= max) {
          return true;
        }
        return `Esse campo deve ter no máximo ${max} caracteres`;
      },
      $maxValue(value, max, message) {
        return value > max
          ? message || `Esse campo deve ter um valor máximo de ${max}!`
          : true
      },
      $minValue(value, min, message) {
        return value < min
          ? message || `Esse campo deve ter um valor mínimo de ${min}!`
          : true;
      },
      $equals(first, second, message = "Campos incompatíveis!") {
        return first === second || message;
      },
      $email(value, domains = [], message = "Esse campo deve conter um email válido!") {
        if (typeof value == "string" && value.length) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let isValid = pattern.test(value);
  
          if (domains.length > 0) {
            isValid = domains.filter((d) => d.toLowerCase() === value.toLowerCase().split("@")[1]).length > 0;
          }
          return isValid || message;
        } else {
          return message;
        }
      },
      $onlyTextAndNumbers(value, message = "Esse campo deve não deve conter caracteres especiais!") {
        const regex = new RegExp('([a-zA-Z0-9]+)$');
        return regex.test(value) || message;
      },
      $formatValue(value) {
        return value.match(/\d/g).join("");
      },
  
      $validarDate(value) {
        let dataInserida = new Date(value);
        let minDate = new Date('1922-02-01')
        return dataInserida < minDate ? `Insira uma data válida` : true;
      },
    }
  };
  
  export default mixin;
  