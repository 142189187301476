import axios from 'axios';
import { AUTH_NAMESPACE, TOKEN } from "store/modules/auth";

const AxiosPlugin = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        $api(baseURL = options.baseURL) {
          const token = this.$store.getters[`${AUTH_NAMESPACE}/${TOKEN}`];
          const header = token ? {
            Authorization: `Bearer ${token}`
          } : undefined;

          return axios.create({
            baseURL: baseURL,
            headers: header,
          });
        },

        $http(baseURL = options.baseURL) {
          return axios.create({
            baseURL: baseURL,
          });
        },
      },
    });
  },
};

export default AxiosPlugin;