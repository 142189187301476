const baseURL = "/Administracao/mantenedores";

const service = {
  methods: {
    // Usuário 
    $cadastrarMantenedor(params) {
      return this.$api().post(`${baseURL}`, params);
    },
    $obterMantenedor(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $atualizarMantenedor(body) {
      return this.$api().put(`${baseURL}`, body);
    },
    $inativarAtivarMantenedor(body) {
      return this.$api().put(`${baseURL}/alterarStatus`, body);
    },
    $visualizarMantenedor(id, params) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $obterQualificacoes(params) {
      return this.$api().get(`${baseURL}/qualificacoes`, params);
    }
  },
};

export default service;
