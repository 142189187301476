export default {
  env: {
    nodeEnv: process.env.NODE_ENV,
    prodTip: process.env.PROD_TIP,
    devTools: process.env.DEV_TOOLS,
  },
  app: {
    name: process.env.VUE_APP_NAME,
    alias:  process.env.VUE_APP_ALIAS,
    version: process.env.VUE_APP_VERSION,
  },
  layout: {
    preLogin: {
      backgroundImageUri: process.env.VUE_APP_LAYOUT_PRE_LOGIN_BACKGROUND_IMAGE_URI || '/img/bg-01.jpg',
    },
  },
  api: {
    baseUri: process.env.VUE_APP_API_BASE_URI,
    auth: {
      authenticateUri: process.env.VUE_APP_API_AUTH_AUTHENTICATE_URI || '/v1/acesso/Autenticar',
      passwordRecoveryUri: process.env.VUE_APP_API_AUTH_PASSWORD_RECOVERY_URI || '/v1/acesso/SolicitarRecuperacaoSenha',
      newPasswordUri: process.env.VUE_APP_API_AUTH_NEW_PASSWORD_URI || '/v1/acesso/MudarSenha',
      refreshTokenUri: process.env.VUE_APP_API_AUTH_REFRESH_TOKEN_URI || '/v1/acesso/RenovarToken',
      getMenuUri: (nomeDeUsuario) => (process.env.VUE_APP_API_AUTH_GET_MENU_URI || '/v1/acesso/Usuario/{nomeDeUsuario}/Menu').replace('{nomeDeUsuario}', encodeURI(nomeDeUsuario)),
      getUserPermissionsUri: (nomeDeUsuario) => (process.env.VUE_APP_API_AUTH_GET_USER_PERMISSIONS_URI || '/v1/acesso/Usuario/{nomeDeUsuario}/Permissoes').replace('{nomeDeUsuario}', encodeURI(nomeDeUsuario)),
      groupsUri: process.env.VUE_APP_API_AUTH_GROUPS_URI || '/v1/acesso/Grupos',
      permissionsUri: process.env.VUE_APP_API_AUTH_PERMISSIONS_URI || '/v1/acesso/Permissoes',
      patchGroupPermissionUri: (idDoGrupo, idDaPermissao) => (process.env.VUE_APP_API_AUTH_PATCH_GROUP_PERMISSION_URI || '/v1/acesso/Grupos/{IdDoGrupo}/Permissao/{IdDaPermissao}').replace("{IdDoGrupo}", idDoGrupo).replace("{IdDaPermissao}", idDaPermissao),
    },
    home: {
      sections: process.env.VUE_APP_API_HOME_SECTIONS_URI,
      faq: process.env.VUE_APP_API_HOME_FAQ_URI
    }
  },
  google: {
    id: process.env.VUE_APP_GOOGLE_ID,
  }
}