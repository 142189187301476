<template>
  <v-app id="app-wrapper">
    <div v-if="displayBreakpoint" id="breakpoint">{{ $vuetify.breakpoint.name }}</div>
    <vue-page-transition name="fade-in-right">
      <router-view class="layout-root" />
    </vue-page-transition>

    <v-snackbar
      v-model="$root.notification.visible"
      v-bind="$root.notification"
      :class="{ 'snack__action--hidden': hideNotificationButton }"
    >
      <div v-html="$root.notification.content"></div>
      <template v-if="$root.notification.closable" #action="{ attrs }">
        <v-btn :light="$root.notification.light"  text v-bind="attrs" @click="closeNotification()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <page-loader v-model="$root.loading.visible"></page-loader>
    
    <div class="d-none">Versão do sistema: {{ appVersion }}</div>

    <app-updater :app-name="appName" />
  </v-app>
</template>

<script>
import configurations from 'commons/configurations'
import PageLoader from "~/components/PageLoader.vue";
import AppUpdater from './components/AppUpdater.vue';

export default {
  name: "Login",
  components: { PageLoader, AppUpdater },
  data() {
    return {
      appName: configurations.app.name,
      appVersion: configurations.app.version,
      displayBreakpoint: configurations.env.nodeEnv !== 'production'
    }
  },
  computed: {
    hideNotificationButton() {
      // ternário foi necessário pois nem sempre a propriedade é boolean
      return this.$root.notification.closable ? false : true;
    },
  },
  methods: {
    closeNotification() {
      this.$root.notification.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/**
  * Esconde o espaço do botão de fechar
  * quando a snackbar não for fechável
  */
::v-deep .v-snack.snack__action--hidden {
  .v-snack__action {
    display: none;
  }
}

#breakpoint {
  background: yellow;
  border: 1px solid red;
  color: red;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
}
</style>