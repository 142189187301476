<template>
  <v-app id="app-wrapper">
    <v-app-bar id="topbar-post-login" color="transparent" dark dense fixed prominent>
      <v-toolbar-title class="d-flex">
        <template>
          <router-link to="/app">
            <img class="app-icon" src="/img/logo-nova.png" alt="brasão-home-64px" />
          </router-link>
          <v-flex class="d-flex align-center" xs12>
            <span class="ml-5" v-text="projectName"></span>
          </v-flex>
        </template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template>
        <v-subheader class="white--text">{{ $user.fullName }}</v-subheader>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!$user.isSadUser"
            icon
            v-bind="attrs"
            v-on="on"
            @click="solicitarRecuperacaoSenha()"
          >
            <v-icon>mdi-lock-reset</v-icon>
          </v-btn>
        </template>
        Trocar Senha
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-4 ml-4" icon v-bind="attrs" v-on="on" @click="logout">
            Sair
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        Sair
      </v-tooltip>
      <template v-slot:extension>
        <v-menu v-for="(item, i) in menu" :key="i" open-on-hover offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              elevation="0"
              color="transparent"
              height="100%"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="item.icon" class="mr-1">{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-btn>
          </template>
          <v-list dense flat>
            <v-list-item-group color="primary">
              <v-list-item
                :to="menu.url"
                v-for="(menu, menuIndex) in item.items"
                :key="menuIndex"
              >
                <v-list-item-icon v-if="menu.icon">
                  <v-icon small>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="!permanentSidebar || currentPageHasMenu"
      id="sidebar"
      app
      floating
      fixed
      clipped
      :permanent="permanentSidebar"
      :color="sidebarColor"
      :src="sidebarBackgroundImage"
      v-model="drawer"
      @input="onDrawerToggled"
    >
      <!-- xl and sm -->
      <template v-if="!permanentSidebar">
        <v-list shaped class="mt-11">
          <v-list-group v-for="(item, index) in menu" :key="index" no-action>
            <template #activator>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </template>

            <v-list-item link :to="child.url" v-for="(child, i) in item.items" :key="i">
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>

      <!-- md and up -->
      <template v-else>
        <v-list shaped class="mt-14">
          <v-subheader>{{ currentPageMenu.name }}</v-subheader>
          <v-list-item
            link
            :to="item.url"
            v-for="(item, i) in currentPageMenu.items"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <CurrentPageMenu></CurrentPageMenu>
    <v-main id="main" class="mt-12">
      <v-container fluid>
        <v-row v-if="deferredPrompt">
          <v-col>
            <v-banner dark color="info">
              <div class="d-flex align-center font-weight-light">
                <v-icon color="white" class="mr-4">mdi-information-outline</v-icon>
                <span
                  >Obtenha nosso aplicativo gratuito. Não vai ocupar espaço no seu
                  telefone e também funciona offline!</span
                >
              </div>
              <template #actions>
                <v-btn small text class="ml-5" @click="dismissPwa">Ignorar</v-btn>
                <v-btn small text @click="installPwa">Instalar</v-btn>
              </template>
            </v-banner>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <vue-page-transition name="fade-in-right">
              <router-view
                class="page"
                @onDrawerToggled="onDrawerToggled"
                :drawer="drawer"
              />
            </vue-page-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <MudarSenhaComponent
      :visible="trocarSenha.visible"
      :content="trocarSenha"
      @onClose="trocarSenha.visible = false"
    ></MudarSenhaComponent>
  </v-app>
</template>

<script>
import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  BACKGROUND_IMAGE,
  BACKGROUND_IMAGE_VISIBILITY,
} from "store/modules/pre-login-layout";
import { AUTH_NAMESPACE, MENU, UPDATE_MENU, UPDATE_ABILITIES } from "store/modules/auth";
import CurrentPageMenu from "components/CurrentPageMenu.vue";
import MudarSenhaComponent from "components/MudarSenhaComponent.vue";
import configurations from "commons/configurations";
import { TrocarSenha } from "~/commons/classes";

import { ConvertPermissionsIntoAbilities } from "~/services/abilities/ability";

const pwaDeferrerdPromptKey = "pwa-add-to-home-screen";

export default {
  name: "PostLoginLayout",
  components: { CurrentPageMenu, MudarSenhaComponent },
  data: () => ({
    projectName: "Sistema de Cadastro e Acompanhamento Escolar",
    app: {
      name: configurations.app.name,
      alias: configurations.app.alias,
    },
    deferredPrompt: null,
    drawer: null,
    trocarSenha: TrocarSenha.Build(),
  }),
  beforeMount() {
    if (this.$user.acceptedTerms === true) {
      this.$api()
        .get(configurations.api.auth.getMenuUri(this.$user.username))
        .then(({ data }) => {
          return this[UPDATE_MENU](this.getTransformedMenu(data.itens));
        })
        .then(() => {
          return this.$api().get(
            configurations.api.auth.getUserPermissionsUri(this.$user.username)
          );
        })
        .then(({ data }) => {
          const abilities = ConvertPermissionsIntoAbilities(data.permissoes);
          this[UPDATE_ABILITIES](abilities);
          return this.updateAbility(abilities);
        });
    }
  },
  computed: {
    backgroundImageSrc() {
      return this.$store.getters[`${PRE_LOGIN_LAYOUT_NAMESPACE}/${BACKGROUND_IMAGE}`];
    },
    backgroundImageIsVisible() {
      return this.$store.getters[
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${BACKGROUND_IMAGE_VISIBILITY}`
      ];
    },
    menu() {
      return this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`];
    },
    currentPageMenu() {
      return this.menu && this.menu.find(
        (m) =>
          (m.url &&
            m.url.toLocaleLowerCase() === this.$route.fullPath.toLocaleLowerCase()) ||
          (m.items &&
            m.items.find(
              (child) =>
                child.url &&
                child.url.toLocaleLowerCase() === this.$route.fullPath.toLocaleLowerCase()
            ))
      );
    },
    currentPageHasMenu() {
      if (
        this.currentPageMenu &&
        this.currentPageMenu.items &&
        this.currentPageMenu.items.length
      ) {
        return true;
      }

      return false;
    },
    permanentSidebar() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    sidebarBackgroundImage() {
      return !this.permanentSidebar ? this.backgroundImageSrc : null;
    },
    sidebarColor() {
      return this.permanentSidebar ? "transparent" : "white";
    },
    currentPageTile() {
      return this.$route.meta.title;
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();

      // Stash the event so it can be triggered later.
      const pwaPrompCache = localStorage.getItem(pwaDeferrerdPromptKey);
      if (pwaPrompCache === "" || pwaPrompCache === null) {
        this.deferredPrompt = e;
      }
    });

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  
  methods: {
    updateAbility(permissions) {
      this.$ability.update(permissions);
    },
    getTransformedMenu(grupos) {
      if (grupos) {
        let menu = [];

        for (let grupo of grupos) {
          let menuItem = {
            icon: grupo.icone,
            name: grupo.nomeDeExibicao,
            url: grupo.endereco,
            items: [],
          };

          if (Array.isArray(grupo.itens)) {
            for (let item of grupo.itens) {
              menuItem.items.push({
                icon: item.icone,
                name: item.nomeDeExibicao,
                url: item.endereco,
              });
            }

            menu.push(menuItem);
          }
        }
        return menu;
      }

      return [];
    },
    async onDrawerToggled(state) {
      this.drawer = state;
    },
    async onToggleClicked() {
      this.drawer = !this.drawer;
    },
    async logout() {
      this.$router.push({ path: "/login" });
    },
    async dismissPwa() {
      localStorage.setItem(pwaDeferrerdPromptKey, null);
      this.deferredPrompt = null;
    },
    async installPwa() {
      this.deferredPrompt.prompt();
    },
    solicitarRecuperacaoSenha() {
      this.trocarSenha.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#app-wrapper {
  background: #f5f5f5;

  #background-image {
    position: fixed;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
:deep(#topbar-post-login) {
  &::before {
    content: "";
    display: none;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(47, 143, 204);
  }
  .v-image__image.v-image__image--cover {
    background-position: 0 0 !important;
  }
  .app-icon {
    max-height: 32px;
  }
}

#sidebar {
  margin-top: 144px;
  height: calc(100% - 144px) !important;
  z-index: 1;
}

#main {
  margin-top: 144px !important;
  .page {
    min-height: 75vh;
  }
}
</style>
