<template>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="small" icon v-bind="attrs" v-on="on" @click="$emit('click')" :disabled="disabled" >
          <v-icon small class="mr-2" :color="color"> {{ icon }} </v-icon>
        </v-btn>
        <v-btn
          v-else
          :color="color"
          :disabled="disabled"
          class="mr-4"
          v-bind="attrs"
          v-on="on"
          @click="$emit('click')"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
  </template>
  <script>
  export default {
    name: "TooltipComponent",
    props: {
      text: {
        type: String,
      },
      icon: {
        type: String,
      },
      color: {
        type: String,
        default: "primary",
      },
      small: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
  };
  </script>