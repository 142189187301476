const baseURL = "/Administracao";

const service = {
  methods: {
    $obterRelatorioUsuarios(params) {
        return this.$api().get(`${baseURL}/RelatorioUsuarios`, params);
    },
    $obterRelatorioUsuariosSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosSintetico`, params);
    },
    $obterRelatorioCSVUsuarios(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosCsv`, params);
    },
    $obterRelatorioCSVUsuariosSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosSinteticoCsv`, params);
    },
    $obterRelatorioPdfUsuarios(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosPdf`, params);
    },
    $obterRelatorioPdfUsuariosSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosSinteticoPdf`, params);
    },

    // Mantenedores 
    $obterRelatorioMantenedores(params) {
        return this.$api().get(`${baseURL}/RelatorioMantenedores`, params);
    },
    $obterRelatorioMantenedoresSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioMantenedoresSintetico`, params);
    },
    $obterRelatorioCSVMantenedores(params) {
      return this.$api().get(`${baseURL}/RelatorioMantenedoresCsv`, params);
    },
    $obterRelatorioCSVMantenedoresSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioMantenedoresSinteticoCsv`, params);
    },
    $obterRelatorioPDFMantenedores(params) {
      return this.$api().get(`${baseURL}/RelatorioMantenedoresPdf`, params);
    },
    $obterRelatorioPDFMantenedoresSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioMantenedoresSinteticoPdf`, params);
    },

    // LOG Mantenedores

    $obterRelatorioLogMantenedores(params) {
      return this.$api().get(`${baseURL}/RelatorioLogMantenedores`, params);
    },
    $obterRelatorioLogCSVMantenedores(params) {
      return this.$api().get(`${baseURL}/RelatorioLogMantenedoresCsv`, params);
    },
    $obterRelatorioLogPDFMantenedores(params) {
      return this.$api().get(`${baseURL}/RelatorioLogDeMantenedoresPDF`, params);
    }
  }
};

export default service;