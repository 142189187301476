<template>
    <div class="d-flex col" :class="`col-${cols}`">
      <v-select
        label="Qualificação"
        :items="qualificacoes"
        :item-text="'nome'"
        :item-value="'id'"
        v-model="localValue"
        outlined
        dense
      ></v-select>
    </div>
</template>
  
<script>
  export default {
    name: "QualificacaoComponent",
    props: {
      value: {
        type: Number,
        default: 0,
      },
      cols: {
        type: Number,
        default: 12,
      },
    },
    data() {
      return {
        localValue: this.value,
        qualificacoes: []
      };
    },
    watch: {
      localValue(v) {
        this.$emit("input", v);
      },
    },
    mounted() {
      this.$obterQualificacoes({ ativo: true }).then(({ data }) => {
        this.qualificacoes = data.qualificacoes;
      });
    }
  };
</script>
