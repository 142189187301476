<template>
  <v-navigation-drawer
      v-if="currentPageHasMenu"
      id="sidebar"
      app
      floating
      fixed
      clipped
      :permanent="permanentSidebar"
      :color="sidebarColor"
      :src="sidebarBackgroundImage"
      v-model="currentDrawer"
      @input="onDrawerToggled"
    >
      <v-avatar
        v-if="!permanentSidebar"
        class="d-block text-center mx-auto mt-4"
        size="36"
      >
        <img class="app-icon" alt="Brasão de Pernambuco" src="/img/logo-SEE-branca-1-2.png" />
      </v-avatar>

      <v-divider class="mt-16 border-0"></v-divider>

      <v-list shaped>
        <v-subheader v-text="currentPageMenu.displayName"></v-subheader>
        <v-list-item link :to="item.endereco" v-for="(item, i) in currentPageMenu.itens" :key="i">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>            
            <v-list-item-title v-text="item.displayName">
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import { AUTH_NAMESPACE, MENU } from "store/modules/auth";

export default {
  name: "CurrentPageMenu",
  props: {
    drawer: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      pDrawer: this.drawer
    };
  },
  computed: {
    currentDrawer: {
      get() {
        return this.pDrawer;
      },
      set(value) {
        this.pDrawer = value;
      }
    },
    menu() {
      return this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`];
    },
    currentPageMenu() {
      return this.menu &&  this.menu.find(m => m.endereco === this.$route.fullPath.toLocaleLowerCase());
    },
    currentPageHasMenu() {
      if(this.currentPageMenu && this.currentPageMenu.itens && this.currentPageMenu.itens.length) {
        return true
      }

      return false;
    },
    permanentSidebar() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    sidebarColor() {
      return this.permanentSidebar ? "transparent" : "white";
    },    
    sidebarBackgroundImage() {
      return !this.permanentSidebar ? this.backgroundImageSrc : null;
    },
  },
  methods: {
    onDrawerToggled(state) {
      this.$emit('onDrawerToggled', state);
    },
  }
}
</script>