const baseURL = "/Administracao/unidade-ensino";
const baseURLAdmUnidade = "/Administracao";

const service = {
  methods: {
    $buscarDadosUnidadeDeEnsino(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $visualizarDadosUnidadeDeEnsino(id) {
      return this.$api().get(`${baseURL}/${id}`);
    },
    $cadastrarUnidadeDeEnsino(params) {
      return this.$api().post(`${baseURL}`, params);
    },
    $editarUnidadeDeEnsino(body) {
      return this.$api().put(`${baseURL}`, body);
    },
    $inativarAtivarPerguntaUnidadeDeEnsino(body) {
      return this.$api().patch(`${baseURL}`, body);
    },
    $obterDependenciaAdm(params) {
      return this.$api().get(`${baseURLAdmUnidade}/dependencia-administrativa`, params);
    },
    $obterSitFuncionamento(params) {
      return this.$api().get(`${baseURLAdmUnidade}/situacao-funcionamento`, params);
    },
    $obterIdentificacao(params) {
      return this.$api().get(`${baseURLAdmUnidade}/identificacao`, params);
    },
    $obterMantenedorUnidadeEnsino(params) {
      return this.$api().get(`${baseURLAdmUnidade}/unidade-ensino-mantenedor`, params);
    },
    $obterBairros(params) {
      return this.$api().get(`${baseURLAdmUnidade}/bairro`, params);
    },
    $buscarOfertasDeEnsinoAtivas(params) {
      return this.$api().get(`${baseURLAdmUnidade}/unidade-ensino-ofertas-ensino`, params);
    }
  },
};

export default service;
