import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.small)?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"small":"","color":_vm.color}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_c(VBtn,_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":_vm.color,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.text))])])}
var staticRenderFns = []

export { render, staticRenderFns }