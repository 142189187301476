const baseURL = "/Administracao/finalidades";

const service = {
  methods: {
    $obterFinalidades(params) {
        return this.$api().get(`${baseURL}`, params);
    },
    $visualizarFinalidade(id, params) {
        return this.$api().get(`${baseURL}/${id}`, params);
    },

    $inativarAtivarFinalidade(body) {
        return this.$api().patch(`${baseURL}/alterarStatus`, body);
    },

    $cadastrarFinalidade(params) {
        return this.$api().post(`${baseURL}`, params);
    },

    $atualizarFinalidade(body) {
        return this.$api().put(`${baseURL}`, body);
    },
  },
};

export default service;