const baseURL = "/Administracao/usuarios";

const service = {
  methods: {
        // Usuário 
        $cadastrarUsuario(params) {
            return this.$api().post(`${baseURL}`, params);
        },
        $obterUsuario(params) {
            return this.$api().get(`${baseURL}`, params);
        },
        $atualizarUsuario(body) {
            return this.$api().put(`${baseURL}`, body);
        },
        $inativarAtivarUsuario(body) {
            return this.$api().put(`${baseURL}/alterarStatus`, body);
        },
        $visualizarUsuario(id) {
            return this.$api().get(`${baseURL}/${id}`);
        },
  },
};

export default service;
