import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"app-wrapper"}},[(_vm.displayBreakpoint)?_c('div',{attrs:{"id":"breakpoint"}},[_vm._v(_vm._s(_vm.$vuetify.breakpoint.name))]):_vm._e(),_c('vue-page-transition',{attrs:{"name":"fade-in-right"}},[_c('router-view',{staticClass:"layout-root"})],1),_c(VSnackbar,_vm._b({class:{ 'snack__action--hidden': _vm.hideNotificationButton },scopedSlots:_vm._u([(_vm.$root.notification.closable)?{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"light":_vm.$root.notification.light,"text":""},on:{"click":function($event){return _vm.closeNotification()}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}:null],null,true),model:{value:(_vm.$root.notification.visible),callback:function ($$v) {_vm.$set(_vm.$root.notification, "visible", $$v)},expression:"$root.notification.visible"}},'v-snackbar',_vm.$root.notification,false),[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$root.notification.content)}})]),_c('page-loader',{model:{value:(_vm.$root.loading.visible),callback:function ($$v) {_vm.$set(_vm.$root.loading, "visible", $$v)},expression:"$root.loading.visible"}}),_c('div',{staticClass:"d-none"},[_vm._v("Versão do sistema: "+_vm._s(_vm.appVersion))]),_c('app-updater',{attrs:{"app-name":_vm.appName}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }